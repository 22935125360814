import React, { memo } from 'react';
import { Input as SemanticInput } from 'semantic-ui-react';
import { useField, ErrorMessage } from 'formik';
import styled from '@emotion/styled';

const RedText = styled.span`
  color: red !important;
`;

type InputProps = {
  name: string;
  label?: string;
  [x: string]: any;
};

const Input: React.FC<InputProps> = memo(({ label, ...props }) => {
  const [field] = useField(props);

  return (
    <span className="field">
      <label htmlFor={props.id || props.name}>
        {label}
        <SemanticInput {...field} {...props} />
      </label>
      <ErrorMessage component={RedText} name={props.name} />
    </span>
  );
});

export default Input;
