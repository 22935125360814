import React from 'react';
import './App.css';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Global, css } from '@emotion/core';
import { ThemeProvider } from 'emotion-theming';
import client from './graphql/ApolloClient';
import BusinessHome from './components/BusinessHome';
// import FeldbotschaftHome from './components/FeldbotschaftHome';
import Brett from './assets/bg-brett.jpg';
import BrettWebp from './assets/webp/bg-brett.webp';
import theme from './styles/theme';
import withAnalytics from './components/withAnalytics';
// import PropsRoute from './components/PropsRoute';
import VerifyEmail from './components/VerifyEmail';
import NotFound from './components/NotFound';
import 'semantic-ui-css/semantic.min.css';

const globalStyles = css`
  body {
    background-image: url(${Brett});
    background-image: -webkit-image-set(
      url(${BrettWebp}) type('image/webp'),
      url(${Brett}) type('image/jpg')
    );
    background-image: image-set(
      url(${BrettWebp}) type('image/webp'),
      url(${Brett}) type('image/jpg')
    );
    background-position: right -84px top;
    background-repeat: repeat;

    * {
      box-sizing: border-box;
      outline: none;
    }
  }

  .ReactModal__Body--open #root {
    > div:nth-of-type(2) {
      filter: blur(2px);
    }
  }

  .ReactModal__Content {
    border-radius: 8px !important;
    border: 1px solid #ededed !important;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
  }

  div[class*='CatalogGrid__GridContainer'] {
    @media (max-width: 650px) {
      display: flex;
      flex-direction: column;
    }
  }
`;

const App = () => (
  <ApolloProvider client={client}>
    <Global styles={globalStyles} />
    <Helmet>
      <meta name="robots" content="none" />
    </Helmet>
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path="/verify/:token" component={withAnalytics(VerifyEmail)} />
          {/* <PropsRoute
            exact
            path="/business"
            component={withAnalytics(BusinessHome)}
            appSelection="Business"
          /> */}
          {/* <Route exact path="/feldbotschaft" component={withAnalytics(FeldbotschaftHome)} /> */}
          <Route path="/" component={withAnalytics(BusinessHome)} />
          <Route component={withAnalytics(NotFound)} />
        </Switch>
      </Router>
    </ThemeProvider>
  </ApolloProvider>
);

export default App;
