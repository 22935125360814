import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { useCookies } from 'react-cookie';
import { Message } from 'semantic-ui-react';
import { FormikErrors } from 'formik';
import { Form } from '../wrappers/formik-semantic/index';
import { LOGIN } from '../graphql/queries';
import { useImperativeQuery } from '../helpers/apollo';
import config from '../config';
import { StyledFormikInput } from '../shared/Inputs';
import { GreenAnchor } from '../shared/Links';
import { identify, trackLoginError, trackLoginStarted } from '../helpers/tracking';

const StyledForm = styled.div`
  &.loginwrapper {
    .field {
      position: relative;
      display: block;
      margin: 0 0 0.75em;

      label + span {
        position: absolute;
        top: 0px;
        right: 0px;
        font-size: 0.92857143em;
      }
    }
  }
`;

type LoginProps = {
  shopSelection?: string;
  setSubmitButtonRef: (el: HTMLButtonElement | null) => void;
};

type formProps = {
  errors: FormikErrors<{ [field: string]: any }>;
  dirty: boolean;
};

const Login = ({ shopSelection, setSubmitButtonRef }: LoginProps) => {
  const [, setCookie] = useCookies(['token']);
  const login = useImperativeQuery(LOGIN);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [hasInteracted, setHasInteracted] = useState<boolean>(false);
  const [hasErrors, setHasErrors] = useState<any>();

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .required('Erforderlich')
      .matches(
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Ungültige E-Mail'
      ),
    password: Yup.string().required('Erforderlich'),
  });

  const handleSubmit = async (values: any, formikApi: any) => {
    try {
      const { data } = await login(values);

      let redirectPage = config.shop.baseURL;
      if (!data.login.shopSelections || data.login.shopSelections[0] === 'Feldbotschaft') {
        redirectPage = config.feldbotschaft.baseURL;
      }

      // begin temporary
      // remove once "login preference" has been added for hybrid users
      if (data.login.shopSelections && data.login.shopSelections.length > 1) {
        redirectPage =
          shopSelection === 'Business' ? config.shop.baseURL : config.feldbotschaft.baseURL;
      } // end temporary

      if (
        data.login.shopSelections &&
        data.login.shopSelections.length === 1 &&
        data.login.shopSelections[0] === 'Feldbotschaft'
      ) {
        setErrorMessage(
          <>
            Anmeldung leider nicht möglich, da das Angebot für Privatkundschaft eingestellt wurde.
            Unsere Online-Services richten sich nur noch an Geschäftskundschaft. Mehr Infos
            gibt&apos;s in{' '}
            <a href="https://querfeld.bio/blog/warum-wir-aufhoren-mussten">diesem Blog Beitrag</a>
          </>
        );
        return;
      }

      if (data.login.shopSelections && data.login.shopSelections.length === 0) {
        setErrorMessage(
          <>
            Für diesen Account ist derzeit keine App-Präferenz ausgewählt. Bitte kontaktieren sie
            unseren Kundenservice unter{' '}
            <a
              href="mailto: kundenservice@querfeld.bio"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'darkred', textDecoration: 'underline' }}
            >
              kundenservice@querfeld.bio
            </a>{' '}
            und geben Sie an, ob sie Zugriff auf den Feldbotschaft- oder den Businessshop erhalten
            möchten.
          </>
        );
        return;
      }

      setCookie('token', data.login.token, { path: '/', domain: config.cookieDomain });
      setCookie('uid', data.login.id, { path: '/', domain: config.cookieDomain });
      localStorage.setItem('shopPreference', shopSelection || 'Business');
      await identify(data.login.id);
      window.location.assign(`${redirectPage}/login`);
    } catch (error) {
      setErrorMessage(error.message);
    }
    formikApi.setSubmitting(false);
  };

  useEffect(() => {
    if (!hasErrors) return;
    trackLoginError(hasErrors, shopSelection === 'Feldbotschaft' ? 'B2C Login' : 'B2B Login');
    // eslint-disable-next-line
  }, [hasErrors]);

  useEffect(() => {
    if (!hasInteracted) return;
    trackLoginStarted(shopSelection === 'Feldbotschaft' ? 'B2C Login' : 'B2B Login');
    // eslint-disable-next-line
  }, [hasInteracted]);

  return (
    <Form
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={loginSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {(props: formProps) => {
        const { errors, dirty } = props;
        !hasInteracted && dirty && setHasInteracted(true);
        if (Object.keys(errors).length !== 0 || errorMessage) {
          const formatedErrorMessage =
            errorMessage ||
            Object.keys(errors)
              .map((e) => {
                return `${e}: ${errors[e]}`;
              })
              .toString();
          formatedErrorMessage !== hasErrors && setHasErrors(formatedErrorMessage);
        }
        return (
          <StyledForm className="loginwrapper">
            {errorMessage && (
              <Message negative>
                <Message.Header>Hoppla!</Message.Header>
                {errorMessage}
              </Message>
            )}
            <StyledFormikInput name="email" type="email" label="Email" />
            <StyledFormikInput name="password" label="Passwort" type="password" />
            <GreenAnchor href={`${config.shop.baseURL}/forgot`}>Passwort vergessen?</GreenAnchor>
            <button hidden type="submit" ref={(el) => setSubmitButtonRef(el)} />
          </StyledForm>
        );
      }}
    </Form>
  );
};

export default Login;
