import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import { Header, Loader } from 'semantic-ui-react';
import { VERIFY_TOKEN } from '../graphql/queries';
import { GreenButton } from '../shared/Buttons';
import errorCircle from '../assets/error_icon_big_1x.png';
import { GreenAnchor } from '../shared/Links';
import NavigationBar from './NavigationBar';
import herzKartoffel from '../assets/herzkartoffel@2x.png';
import TickMark from '../assets/tick-mark.svg';

type ThemeProps = {
  theme?: any;
};

const PageContainer = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`;

const CenteredLoader = styled(Loader)`
  &&& {
    margin: 40% auto;

    @media (max-width: 420px) {
      margin: 80% auto;
    }
  }
`;

const ApplicationBox = styled.div`
  background: white;
  min-width: 40vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 65px 74px 37px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 0 10px;
  border-radius: 6px;
  position: relative;
  min-width: 620px;

  &.ApplicationBox--icon {
    margin-top: 70px;
    padding-top: 58px;
  }

  h3.ui.header {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    margin: 0;
    margin-bottom: 27px;
  }

  @media only screen and (max-width: 767px) {
    min-width: 100vw;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 1rem;

    &.ApplicationBox--icon {
      margin-top: 0px;
      padding-top: 140px;
    }
  }
`;

const StyledGreenButton = styled(GreenButton)`
  padding: 6px 5rem !important;
`;

const HerzKartoffel = styled.img`
  margin-left: auto;
  margin-right: auto;
  margin-top: -5%;
  width: 250px;

  @media only screen and (max-width: 767px) {
    width: 300px;
  }
`;

const HeaderIconContainer = styled.div`
  position: absolute;
  top: -45px;
  width: 91px;
  height: 91px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }: ThemeProps) => theme.colors.green};
  border-radius: 100px;

  @media only screen and (max-width: 767px) {
    top: 35px;
  }
`;

const HeaderIcon = styled.img`
  width: 40px;
`;

const VerifyEmail = () => {
  const history = useHistory();
  const { token } = useParams();
  const { data, loading, error } = useQuery(VERIFY_TOKEN, { variables: { token } });

  if (loading) return <CenteredLoader active inline="centered" />;
  return (
    <PageContainer>
      <Helmet>
        <link rel="canonical" href={`${window.location.origin}${window.location.pathname}`} />
      </Helmet>
      <NavigationBar
        setAuthTabIndex={() => {
          history.push('/feldbotschaft');
        }}
      />
      <ApplicationBox className="ApplicationBox--icon">
        <HeaderIconContainer>
          <HeaderIcon src={TickMark} />
        </HeaderIconContainer>
        {error || !data ? (
          <>
            <img alt="error circle" src={errorCircle} />
            <Header as="h3">Anmeldung fehlgeschlagen</Header>
            <div className="greytext">
              Hoppla, das hätte nicht passieren dürfen. Bitte probiere es erneut oder kontaktiere
              uns unter{' '}
              <GreenAnchor
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:nachbarn@querfeld.bio"
              >
                nachbarn@querfeld.bio
              </GreenAnchor>
              .
            </div>
          </>
        ) : (
          <>
            <Header as="h3">Registrierung abgeschlossen</Header>
            <p>Willkommen bei Querfeld</p>
            <HerzKartoffel src={herzKartoffel} alt="Herz Kartofel" />
            <StyledGreenButton onClick={() => history.push('/feldbotschaft')}>
              Jetzt anmelden
            </StyledGreenButton>
          </>
        )}
      </ApplicationBox>
    </PageContainer>
  );
};

export default VerifyEmail;
