import React from 'react';
import { Menu, Button, Header, Icon } from 'semantic-ui-react';
import styled from '@emotion/styled';
import { VeggieStyling } from './VeggiesBackground';
import config from '../config';
import headerLogo from '../assets/querfeld-logo.svg';
import fbLogoWhite from '../assets/feldbotschafter-white.svg';
import fbLogoGreen from '../assets/feldbotschafter-green.svg';
import businessLogoWhite from '../assets/shop-white.svg';
import businessLogoGreen from '../assets/shop-green.svg';
import marketLogoWhite from '../assets/bag-white.svg';
import marketLogoGreen from '../assets/bag-green.svg';
import lauchImg from '../assets/lauch-img.png';
import lauchImgWebp from '../assets/webp/lauch-img.webp';
import tomateImg from '../assets/tomate-img.png';
import tomateImgWebp from '../assets/webp/tomate-img.webp';
import kartoffelImg from '../assets/kartoffel-img.png';
import kartoffelImgWebp from '../assets/webp/kartoffel-img.webp';
import aubergineImg from '../assets/aubergine-img.png';
import aubergineImgWebp from '../assets/webp/aubergine-img.webp';
import parikaImg from '../assets/paprika-img.png';
import parikaImgWebp from '../assets/webp/paprika-img.webp';
import karotteImg from '../assets/karotte-img.png';
import karotteImgWebp from '../assets/webp/karotte-img.webp';
import Cart from '../assets/cart-icon.svg';
import FAQ from '../assets/faq-icon.svg';
import { GreenAnchor } from '../shared/Links';
import ImgNextGen from './ImgNextGen';
import MobileNavMenu from './MobileNavMenu';

type ThemeProps = {
  theme?: any;
};

const WideMenu = styled(Menu)<ThemeProps>`
  &&& {
    font-family: ${({ theme }) => theme.rui_typography.defaultFont.font};
    width: 100%;
    padding: 2vh 16px 2vh;
    box-sizing: border-box;
    height: auto;
    position: absolute;

    @media (min-width: 980px) {
      min-width: 400px;
      width: 30%;
      padding: 9vh 32px 2vh;
      box-sizing: border-box;
      height: 100%;
      position: fixed;
      left: 0px;
      top: 0px;
      bottom: 0px;
    }
  }

  .link-wrapper {
    display: none;
    width: 100%;
    justify-content: center;
    line-height: 13px;
    font-family: ${({ theme }) => theme.rui_typography.defaultFont.font} !important;

    @media (min-width: 980px) {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding-top: 16px;
    }

    span {
      color: ${({ theme }: ThemeProps) => theme.colors.grey};
      margin: 0 8px;
    }

    a {
      color: ${({ theme }: ThemeProps) => theme.colors.green};
      text-decoration: none;
      font-weight: 600;
      font-size: 13px;
      margin-bottom: 12px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .item {
    padding: 0 0 !important;
    max-width: 440px;
    margin: 0 auto;

    .logo-wrapper {
      width: 100%;

      @media (min-width: 980px) {
        margin-bottom: 9vh;
      }

      .logo {
        display: block;
        width: 180px;
        margin: 12px auto;
      }
    }
  }
`;

const StyledHeader = styled(Header)`
  font-size: 1.2em;
  text-align: center;

  @media (min-width: 980px) {
    text-align: left;
  }
`;

const GreyParagraph = styled.p<ThemeProps>`
  color: ${({ theme }: ThemeProps) => theme.colors.grey};
  font-weight: 700;
  font-size: 0.85em;
  line-height: 1.5em;
  margin-bottom: calc(1.5vh + 16px);
  display: none;

  @media (max-width: 980px) {
    display: none;
  }
`;

const GreenButton = styled(Button)<ThemeProps>`
  &&& {
    width: 45%;
    margin: 0 2.5%;
    float: left;
    background-color: white;
    color: ${({ theme }: ThemeProps) => theme.colors.green} !important;
    border: 1.5px solid ${({ theme }: ThemeProps) => theme.colors.green};
    border-radius: 10px;
    box-shadow: 0 0 0 0px rgba(34, 36, 38, 0.15) inset;
    height: 64px;
    height: 8vh;
    min-height: 64px;
    max-height: 94px;
    display: flex;
    align-items: center;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: all 0.1s ease-out;
    cursor: pointer;

    @media (max-width: 360px) {
      font-size: 0.9rem;
      line-height: 1.3rem;
    }

    @media (min-width: 980px) {
      padding-left: 90px;
      padding-right: 16px;
      width: 100%;
      min-height: 80px;
      margin: 0 0;
      margin-bottom: 16px;
      margin-bottom: calc(1vh + 8px);
    }

    &:hover {
      box-shadow: 0 0 0 0px rgba(34, 36, 38, 0.15) inset;
      color: white !important;
      border: 1.5px solid #52a247;
      filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.25))
        drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1));
      transform: scale(1.03);
    }

    &:active {
      transform: scale(1);
      filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));
      border: 1.5px solid #52a247;
    }

    &.business {
      background: url(${businessLogoGreen}) white 28px center no-repeat !important;
      background-size: 38px auto !important;

      &:hover,
      &:not(.basic) {
        background: url(${businessLogoWhite}) ${({ theme }: ThemeProps) => theme.colors.green} 28px
          center no-repeat !important;
        background-size: 38px auto !important;
        color: white !important;
      }
    }

    &.feldbotschaft {
      background: url(${fbLogoGreen}) white 28px center no-repeat !important;
      background-size: 38px auto !important;

      &:hover,
      &:not(.basic) {
        background: url(${fbLogoWhite}) ${({ theme }: ThemeProps) => theme.colors.green} 28px center
          no-repeat !important;
        background-size: 38px auto !important;
        color: white !important;
      }
    }

    &.market {
      background: url(${marketLogoGreen}) white 32px center no-repeat !important;
      background-size: 28px auto !important;

      &:hover {
        background: url(${marketLogoWhite}) ${({ theme }: ThemeProps) => theme.colors.green} 32px
          center no-repeat !important;
        background-size: 28px auto !important;
      }
    }

    /*styling for mobile*/
    @media (max-width: 440px) {
      font-size: 3vw;
    }

    @media (max-width: 979px) {
      padding: 50px 4px 0 4px;
      height: auto;
      min-height: 90px;
      box-sizing: border-box;

      .details {
        display: none;
      }

      .button {
        margin: 0 2.5%;
      }

      div {
        width: 100%;
      }

      &.business {
        background: url(${businessLogoGreen}) white center 14px no-repeat !important;
        background-size: 38px auto !important;

        &:hover,
        &:not(.basic) {
          background: url(${businessLogoWhite}) ${({ theme }: ThemeProps) => theme.colors.green}
            center 14px no-repeat !important;
          background-size: 38px auto !important;
          color: white !important;
        }
      }

      &.feldbotschaft {
        background: url(${fbLogoGreen}) white center 14px no-repeat !important;
        background-size: 38px auto !important;

        &:hover,
        &:not(.basic) {
          background: url(${fbLogoWhite}) ${({ theme }: ThemeProps) => theme.colors.green} center
            14px no-repeat !important;
          background-size: 38px auto !important;
          color: white !important;
        }
      }

      &.market {
        background: url(${marketLogoGreen}) white center 10px no-repeat !important;
        background-size: 28px auto !important;

        &:hover {
          background: url(${marketLogoWhite}) ${({ theme }: ThemeProps) => theme.colors.green}
            center 10px no-repeat !important;
          background-size: 28px auto !important;
        }
      }

      span {
        text-align: center;
      }
    }
  }

  span {
    display: block;
    text-align: left;
    line-height: 1.6em;
    &.label {
      font-weight: 700;
    }

    &.details {
      font-weight: 500;
    }
  }
`;

const MenuIcon = styled.img`
  width: 13px;
  height: 13px;
  margin-right: 4px;
`;

type Props = {
  shopSelection?: string;
  setShopSelection: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const Sidebar = ({ shopSelection, setShopSelection }: Props) => (
  <>
    <WideMenu vertical fixed="left">
      <Menu.Item name="home" className="home-wrapper">
        <MobileNavMenu />
        <div className="logo-wrapper">
          <img src={headerLogo} alt="Querfeld" className="logo" />
        </div>

        <StyledHeader>Unsere Online-Angebote:</StyledHeader>
        <GreyParagraph>
          Schön, dass Sie sich für unser Querfeld-Angebot registrieren möchten. Wählen Sie bitte
          aus, für was Sie sich anmelden wollen:
        </GreyParagraph>

        <GreenButton
          fluid
          basic={shopSelection !== 'Business'}
          className="business"
          onClick={() => setShopSelection('Business')}
        >
          <div>
            <span className="label">Geschäftskundschaft</span>
            <span className="details">Direkte Lieferung, ab 75 Kg</span>
          </div>
        </GreenButton>
        {/* <GreenButton
          fluid
          basic={shopSelection !== 'Feldbotschaft'}
          className="feldbotschaft"
          onClick={() => setShopSelection('Feldbotschaft')}
        >
          <div>
            <span className="label">Feldbotschaft</span>
            <span className="details">Verwalte dein Konto</span>
          </div>
        </GreenButton> */}
        <div className="link-wrapper">
          <GreenAnchor
            href={`${config.website.baseURL}${shopSelection === 'Business' ? '/business' : ''}`}
          >
            <Icon name="home" />
            Zurück zur Querfeld-Website
          </GreenAnchor>
          <GreenAnchor
            href={`${config.website.baseURL}${
              shopSelection === 'Business' ? '/faq_business' : '/faq'
            }`}
          >
            <MenuIcon src={FAQ} alt="Fragen & Antworten" />
            Fragen & Antworten
          </GreenAnchor>
          <GreenAnchor
            href={shopSelection === 'Business' ? config.shop.baseURL : config.feldbotschaft.baseURL}
          >
            <MenuIcon src={Cart} alt="Online shop" />
            Online shop
          </GreenAnchor>
        </div>
      </Menu.Item>
    </WideMenu>
    <VeggieStyling className="portal-bg">
      <ImgNextGen src={lauchImg} srcWebp={lauchImgWebp} alt="lauch" className="lauch" />
      <ImgNextGen src={tomateImg} srcWebp={tomateImgWebp} alt="tomate" className="tomate" />
      <ImgNextGen
        src={kartoffelImg}
        srcWebp={kartoffelImgWebp}
        alt="kartoffel"
        className="kartoffel"
      />
      <ImgNextGen
        src={aubergineImg}
        srcWebp={aubergineImgWebp}
        alt="aubergine"
        className="aubergine"
      />
      <ImgNextGen src={parikaImg} srcWebp={parikaImgWebp} alt="paprika" className="paprika" />
      <ImgNextGen src={karotteImg} srcWebp={karotteImgWebp} alt="karotte" className="karotte" />
    </VeggieStyling>
  </>
);

export default Sidebar;
