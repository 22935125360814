import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from 'apollo-link-context';
import { ApolloLink } from 'apollo-link';
import config from '../config';

const { api } = config;

const consentLink = setContext((_, { headers }) => {
  let consent = '';
  if (
    window.CookieScript &&
    window.CookieScript.instance &&
    typeof window.CookieScript.instance.currentState === 'function'
  ) {
    consent = window.CookieScript.instance.currentState().categories.join(',');
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      consent,
      originApp: 'Portal',
      relevantBusinessChannel: window.location.pathname.includes('business') ? 'B2B' : 'B2C',
    },
  };
});

const httpLink = new HttpLink({
  uri: `${api.baseURL}/graphql`,
});

const cache = new InMemoryCache({
  typePolicies: {
    Article: {
      keyFields: ({ articleId, __typename }) => `${__typename}:${articleId}`,
    },
  },
});

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([consentLink, httpLink]),
});

export default client;
