import styled from '@emotion/styled';
import { Checkbox } from '../wrappers/formik-semantic';

type ThemeProps = {
  theme?: any;
};

export const StyledFormikCheckbox = styled(Checkbox)<ThemeProps>`
  &.ui.checkbox {
    height: 31px;
    line-height: 27px;
    input {
      width: 24px;
      height: 24px;
      top: 2px;

      &:before {
        border-color: ${({ theme }: ThemeProps) => theme.colors.grey};
        border-radius: ${({ theme }: ThemeProps) => theme.borderRadius};
      }
      &:checked ~ label {
        &:after {
          color: #fff;
          width: 24px;
          height: 22px;
          //top: 6px;
          left: 0px;
        }

        &:before {
          background-color: ${({ theme }: ThemeProps) => theme.colors.green};
          border-color: ${({ theme }: ThemeProps) => theme.colors.green};
          border-radius: ${({ theme }: ThemeProps) => theme.borderRadius};
        }
      }

      &:focus ~ label {
        color: ${({ theme }: ThemeProps) => theme.colors.black};
      }
    }

    label {
      padding-left: 40px;
      text-transform: none;
    }

    label:before {
      width: 24px;
      height: 24px;
      border: 1px solid ${({ theme }: ThemeProps) => theme.colors.black};
      border-radius: ${({ theme }: ThemeProps) => theme.borderRadius};
      margin-top: 2px;
    }
  }
`;

export default StyledFormikCheckbox;
