import styled from '@emotion/styled';
import { Button } from 'semantic-ui-react';

type ThemeProps = {
  theme?: any;
};

export const GreenButton = styled(Button)<ThemeProps>`
  &&& {
    font-size: 1em;
    position: relative;
    color: #fff;
    height: 48px;
    min-height: 1em;
    background-color: ${({ theme }) => theme.colors.green};
    border-radius: ${({ theme }) => theme.borderRadius};
    background-color: ${({ theme }) => theme.colors.green};
    border: 2px solid ${({ theme }) => theme.colors.green};
    margin: 0 ${({ theme }) => theme.distances.xs} 0 0;
    padding: 0 0.875rem 0 0.875rem;
    font-weight: 800 !important; /*need to be fixed*/
    line-height: 1em;
    font-style: normal;
    font-family: ${({ theme }: ThemeProps) => theme.rui_typography.defaultFont.font};
    user-select: none !important; /*need to be fixed*/
    transition: background-color ease 0.4s, transform ease 0.2s !important; /*need to be fixed*/
    transform: translateZ(0px);
    cursor: pointer;

    &:after {
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    &:hover {
      transform: scale(1.05);

      &:after {
        opacity: 1;
      }
    }

    &:active {
      transform: scale(1);

      &:after {
        opacity: 0;
      }
    }

    &.secondary {
      background-color: white;
      color: ${({ theme }) => theme.colors.green};
    }
  }
`;

export default GreenButton;
