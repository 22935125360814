export default {
  distances: {
    default: 8,
    xs: '8px',
    s: '16px',
    l: '24px',
    xl: '32px',
  },
  container: {
    widthXl: '1200px',
    widthLg: '900px',
    widthMd: '600px',
    widthSm: '400px',
  },
  borderRadius: '6px',
  fontSizes: {
    default: '1em',
  },
  colors: {
    black: '#333333',
    green: '#64b457',
    grey: '#969696',
    greyLight: '#ededed',
    greyDark: '#333333',
    greenLight: '#E5F1E3',
    brownGrey: '#968C78',
    brownLight: '#f8f7f3',
    brown: '#968C78',
    yellow: '#FFC400',
    blue: '#44A2F2',
    blueLight: '#F1F9FF',
    blueBorder: '#BCE0FD',
    red: '#ee442e',
    redLight: '#FEECE9',
  },
  rui_typography: {
    headingText: {
      color: '#ef2121',
    },
    titleText: {
      color: '#ef2121',
    },
    handwrittenFont: {
      font: '"debbyscript", sans-serif;',
    },
    defaultFont: {
      font: '"semplicitapro", "Helvetica Neue", Arial, Helvetica, sans-serif;',
    },
  },
  rui_components: {
    CatalogGridItemBadgeLabel: {
      color: '#ef2121',
    },
  },
};
