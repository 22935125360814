import React from 'react';
import PropTypes from 'prop-types';

const ImgNextGen = ({ src, srcWebp, alt, ...props }) => {
  return (
    <picture>
      <source srcSet={srcWebp} type="image/webp" />
      <img src={src} alt={alt} {...props} />
    </picture>
  );
};

ImgNextGen.propTypes = {
  src: PropTypes.string.isRequired,
  srcWebp: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

ImgNextGen.defaultProps = {
  alt: '',
};

export default ImgNextGen;
