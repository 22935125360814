import React from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { Dropdown, Icon, Image } from 'semantic-ui-react';
import BusinessIcon from '../assets/shop-icon.svg';
import UserIcon from '../assets/user-icon.svg';
import BagIcon from '../assets/bag-black.svg';
import config from '../config';

const HamburgerIcon = styled.button`
  width: 64px;
  height: 64px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0;
  padding-bottom: 1rem;
  border: none;
  margin: 0 !important;
  background: transparent;
  position: relative;
  transform: rotate(0deg);
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 24px;
    background: black;
    opacity: 1;
    left: 20px;
    transform: rotate(0deg);
    transition-property: transform, top, left, opacity;
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 21px;
      transform-origin: left center;
    }

    &:nth-child(2) {
      top: 30px;
      transform-origin: left center;
    }

    &:nth-child(3) {
      top: 39px;
      transform-origin: left center;
    }
  }
`;

const HamburgerMenu = styled(Dropdown)`
  &&& {
    position: absolute;
    top: 0px;
    margin-top: -10px;
    left: -15px;
    bottom: 0px;
    min-width: 60px;
    height: 60px;
    z-index: 900;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      width: 26px;
      height: 32px;
      margin-left: 8px;
    }

    @media (min-width: 980px) {
      display: none;
    }

    &.active {
      .HambergerMenu {
        span {
          &:nth-child(1) {
            transform: rotate(45deg);
            top: 22px;
            left: 23px;
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:nth-child(3) {
            transform: rotate(-45deg);
            top: 39px;
            left: 23px;
          }
        }
      }
    }

    .menu {
      border: 0px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 0px;
      margin-top: -1px;
      user-select: none;
      padding-bottom: 7px;
      padding-top: 7px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);

      hr {
        border: 0.5px solid #ededed;
        margin: 7px 0 7px 0;
      }

      .item {
        display: flex;
        flex-direction: row;
        padding: 7px 30px 7px 14px !important;
        align-content: center;

        .status {
          width: 32px;
          margin-top: 2px;
          margin-bottom: -2px;
        }

        span {
          display: flex;
          align-items: center;
          &.active {
            font-weight: 700;
          }
        }

        .icon {
          height: 16px;
          width: 16px;
          margin-right: 12px;
          display: inline-block;
          fill: black;

          &.dashboard {
            margin-top: 1px;
            margin-bottom: -1px;
          }

          &.cart-icon {
            margin-top: -2px;
          }

          &.user-icon {
            margin-top: -4px;
            height: 12px;
            width: 12px;
          }

          &.business-icon {
            margin-top: -8px;
          }
        }
      }
    }
  }
`;

const MobileNavMenu = () => {
  const history = useHistory();
  const currentPage = history.location.pathname;

  return (
    <HamburgerMenu
      icon={
        <HamburgerIcon className="HambergerMenu">
          <span />
          <span />
          <span />
        </HamburgerIcon>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => window.location.assign(`${config.feldbotschaft.baseURL}/landing`)}
        >
          <div className="status" />
          <span>
            <Image className="icon" src={BagIcon} alt="" />
            Tüte auswählen
          </span>
        </Dropdown.Item>
        <hr />
        <Dropdown.Item onClick={() => window.location.assign(`${config.website.baseURL}`)}>
          <div className="status" />
          <span>
            <Icon name="world" />
            Querfeld Webseite
          </span>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => window.location.assign(`${config.website.baseURL}/feldbotschaft-agb`)}
        >
          <div className="status" />
          <span>
            <Icon name="handshake" />
            AGB
          </span>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => window.location.assign(`${config.website.baseURL}/datenschutz`)}
        >
          <div className="status" />
          <span>
            <Icon name="unlock alternate" />
            Datenschutz
          </span>
        </Dropdown.Item>
        <hr />
        <Dropdown.Item onClick={() => history.push('/')}>
          <div className="status">
            {(currentPage === '/business' || currentPage === '/feldbotschaft') && (
              <Icon name="circle" size="small" color="green" />
            )}
          </div>
          <span
            className={`${
              (currentPage === '/business' || currentPage === '/feldbotschaft') && 'active'
            }`}
          >
            <Image className="icon" src={UserIcon} alt="" />
            Anmelden/Registrieren
          </span>
        </Dropdown.Item>
        <hr />
        <Dropdown.Item onClick={() => window.location.assign(`${config.website.baseURL}/business`)}>
          <div className="status" />
          <span>
            <Image className="icon" src={BusinessIcon} alt="" />
            Für Gastronomie
          </span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </HamburgerMenu>
  );
};

export default MobileNavMenu;
