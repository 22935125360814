import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import Sidebar from './Sidebar';
import AuthCard from './AuthCard';
import businessLogoWhite from '../assets/shop-white.svg';
import fbLogoWhite from '../assets/feldbotschafter-white.svg';
import { GreenAnchor } from '../shared/Links';
import config from '../config';
import FAQ from '../assets/faq-icon.svg';
import Cart from '../assets/cart-icon.svg';
import { trackPage } from '../helpers/tracking';

type ThemeProps = {
  theme?: any;
};

const FormContainer = styled.div`
  margin: 100px;
  z-index: 100;
  position: relative;

  @media (max-width: 980px) {
    margin: 0 0;
    margin-top: 320px;
  }

  @media (min-width: 980px) {
    margin-left: 400px;
    margin-right: 0px;
    margin-top: 0px;
  }

  @media (min-width: 1330px) {
    margin-left: 30%;
  }

  &.Feldbotschaft,
  &.Business {
    @media (min-width: 980px) {
      display: block;
      margin-top: 170px;
    }

    .ui.card:before {
      content: '';
      display: block;
      margin: -100px auto 20px;
      width: 80px;
      height: 80px;
      background-color: ${({ theme }: ThemeProps) => theme.colors.green};
      border-radius: 100%;
    }
  }

  &.Business .ui.card:before {
    background: url(${businessLogoWhite}) ${({ theme }: ThemeProps) => theme.colors.green} center
      center no-repeat;
    background-size: 38px auto;
  }

  &.Feldbotschaft .ui.card:before {
    background: url(${fbLogoWhite}) ${({ theme }: ThemeProps) => theme.colors.green} center center
      no-repeat;
    background-size: 38px auto;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 14px;
  padding-bottom: 4rem;
  align-items: flex-start;
  padding-left: 2rem;

  @media (min-width: 980px) {
    display: none;
  }

  a {
    font-weight: bold;
    margin-bottom: 12px;
  }
`;

const MenuIcon = styled.img`
  width: 13px;
  height: 13px;
  margin-right: 4px;
`;

interface HomeProps extends RouteComponentProps<any> {
  appSelection?: string;
}

const BusinessHome = ({ location, history, appSelection }: HomeProps) => {
  // const shopPreference = localStorage.getItem('shopPreference');
  const searchParams = new URLSearchParams(location.search);
  const initialTabIndex = searchParams.get('page') === 'registrieren' ? '1' : '0';
  const [shopSelection, setShopSelection] = useState<string | undefined>('Business');
  const [authTabIndex, setAuthTabIndex] = useState<string>(initialTabIndex);

  useEffect(() => {
    const tabIndex = searchParams.get('page') === 'registrieren';
    if ((tabIndex && authTabIndex !== '1') || (!tabIndex && authTabIndex === '1')) {
      authTabIndex === '1'
        ? searchParams.append('page', 'registrieren')
        : searchParams.delete('page');
      const newUrl = `${location.pathname}?${searchParams.toString()}`;
      history.push(newUrl);
      trackPage();
    }
    // eslint-disable-next-line
  }, [authTabIndex]);

  if (shopSelection === 'Feldbotschaft') {
    return <Redirect to="/feldbotschaft" />;
  }

  return (
    <>
      <Sidebar shopSelection={shopSelection} setShopSelection={setShopSelection} />
      {shopSelection && (
        <FormContainer className={shopSelection}>
          <AuthCard
            shopSelection={shopSelection}
            authTabIndex={authTabIndex}
            setAuthTabIndex={setAuthTabIndex}
          />
          <LinkWrapper>
            <GreenAnchor
              href={`${config.website.baseURL}${shopSelection === 'Business' ? '/business' : ''}`}
            >
              <Icon name="home" />
              Zurück zur Querfeld-Website
            </GreenAnchor>
            <GreenAnchor
              href={`${config.website.baseURL}${
                shopSelection === 'Business' ? '/faq_business' : '/faq'
              }`}
            >
              <MenuIcon src={FAQ} alt="Fragen & Antworten" />
              Fragen & Antworten
            </GreenAnchor>
            <GreenAnchor
              href={
                shopSelection === 'Business' ? config.shop.baseURL : config.feldbotschaft.baseURL
              }
            >
              <MenuIcon src={Cart} alt="Online shop" />
              Online shop
            </GreenAnchor>
          </LinkWrapper>
        </FormContainer>
      )}
    </>
  );
};

export default BusinessHome;
