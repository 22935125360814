export const consentGtag = (consent) => {
  function gtag(...args) {
    window.dataLayer.push(args);
  }
  gtag('consent', 'update', { ad_storage: consent ? 'granted' : 'denied' });
  gtag('event', 'consent', { method: consent ? 'granted' : 'denied' });
};

const rudderTrack = (...args) => {
  const tempArgs = args;
  tempArgs[1] = {
    ...(tempArgs[1] || {}),
    origin_app: 'Portal',
    event_timestamp: Date.now(),
    relevant_business_channel: window.location.pathname.includes('business') ? 'B2B' : 'B2C',
  };
  if (!window.rudderanalytics || !window.rudderLoaded || !window.rudderReady) {
    // eslint-disable-next-line
    if (tempArgs[2]) tempArgs[3] = tempArgs[2];
    tempArgs[2] = {
      integrations: {
        All: false,
        'Customer IO': true,
      },
    };
    window.rudderJobs = window.rudderJobs || [];
    window.rudderJobs.push([...tempArgs]);
    return;
  }
  // eslint-disable-next-line
  if (tempArgs[2]) tempArgs[3] = tempArgs[2];
  tempArgs[2] = {
    integrations: window.getRSIntegrations(),
  };
  window.rudderanalytics.track(...tempArgs);
};

export const trackSignup = (type) => {
  window.gtag && window.gtag('event', 'sign_up', { method: type });
};

export const trackPage = (page = window.location.pathname) => {
  // GTAG
  window.gtag &&
    window.gtag('event', 'page_view', {
      page_title: document.title,
      page_location: `${window.location.origin}${page}`,
      page_path: page,
    });

  // GTM
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'pageview',
    page: {
      title: document.title,
      location: `${window.location.origin}${page}`,
      path: page,
    },
  });

  // Rudderstack
  window.rudderLoaded &&
    window.rudderanalytics.page(
      null,
      document.title,
      {
        path: page,
        referrer: document.referrer,
        search: '',
        title: document.title,
        url: `${window.location.origin}${page}`,
        source: typeof window.source === 'function' ? window.source() : '',
      },
      {
        integrations: window.getRSIntegrations(),
      }
    );
};

export const identify = async (userId) => {
  return new Promise((resolve) => {
    if (!window.rudderanalytics || !window.rudderLoaded || !window.rudderReady) {
      // TODO Log error for rudder not loaded
      resolve(false);
    }
    window.rudderanalytics.identify(userId, {}, {}, () => {
      setTimeout(() => {
        resolve(true);
      }, 300);
    });
  });
};

export const trackRegistrationStarted = (customerType) => {
  return new Promise((resolve) => {
    if (!window.rudderanalytics || !window.rudderLoaded || !window.rudderReady) {
      resolve(false);
    }

    rudderTrack(
      'Registration Started',
      {
        origin_app: 'Portal',
        origin_form: 'Registration',
        customer_type: customerType,
      },
      () => setTimeout(() => resolve(true), 300)
    );
  });
};

export const trackLoginStarted = (originForm) => {
  rudderTrack('Login Started', {
    origin_app: 'Portal',
    origin_form: originForm,
  });
};

export const trackRegistrationError = (errorMessage) => {
  return new Promise((resolve) => {
    if (!window.rudderanalytics || !window.rudderLoaded || !window.rudderReady) {
      resolve(false);
    }

    rudderTrack(
      'Registration Form Failed',
      {
        origin_app: 'Portal',
        origin_form: 'Registration',
        error_message: errorMessage,
        customer_type: 'Feldbotschaft User Incomplete',
      },
      () => setTimeout(() => resolve(true), 300)
    );
  });
};

export const trackLoginError = (errorMessage, originForm) => {
  return new Promise((resolve) => {
    if (!window.rudderanalytics || !window.rudderLoaded || !window.rudderReady) {
      resolve(false);
    }

    rudderTrack(
      'Login Form Failed',
      {
        origin_app: 'Portal',
        origin_form: originForm,
        error_message: errorMessage,
      },
      () => setTimeout(() => resolve(true), 300)
    );
  });
};
