import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

type ThemeProps = {
  theme?: any;
};

export const GreenAnchor = styled.a<ThemeProps>`
  color: ${({ theme }: ThemeProps) => theme.colors.green};
  :hover {
    color: ${({ theme }: ThemeProps) => theme.colors.green};
    text-decoration: underline; /* TODO: Standardize anchor styling */
  }
`;

export const GreenLink = styled(Link)<ThemeProps>`
  color: ${({ theme }: ThemeProps) => theme.colors.green};
  :hover {
    color: ${({ theme }: ThemeProps) => theme.colors.green};
    text-decoration: underline; /* TODO: Standardize anchor styling */
  }
`;
