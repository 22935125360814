import React, { memo } from 'react';
import { Dropdown as SemanticDropdown } from 'semantic-ui-react';
import { useField, ErrorMessage } from 'formik';
import styled from '@emotion/styled';

const RedText = styled.span`
  color: red !important;
`;

type DropdownProps = {
  name: string;
  label?: any;
  [x: string]: any;
};

const Dropdown = memo(({ label, ...props }: DropdownProps) => {
  const [field, meta, helpers] = useField(props);

  const { value } = meta;
  const { setValue } = helpers;

  return (
    <span className="field">
      <label htmlFor={props.id || props.name}>
        {label} <br />
        <SemanticDropdown
          selectOnBlur={false}
          {...field}
          {...props}
          value={value}
          onChange={(e, v) => {
            setValue(v.value);
            props.onChangeCallback && props.onChangeCallback(v.value);
          }}
        />
      </label>
      <ErrorMessage component={RedText} name={props.name} />
    </span>
  );
});

export default Dropdown;
