import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/apm';
import './index.css';
import { CookiesProvider } from 'react-cookie';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'semantic-ui-css/semantic.min.css';
import config from './config';

Sentry.init({
  dsn: config.sentryURL,
  integrations: [new Integrations.Tracing()],
  environment: process.env.REACT_APP_ENV,
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  // <React.StrictMode>
  <CookiesProvider>
    <App />
  </CookiesProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
