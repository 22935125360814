import { gql } from '@apollo/client';

export const LOGIN = gql`
  query Login($email: String!, $password: String!) {
    login(email: $email, password: $password, type: "customer") {
      id
      token
      ... on Customer {
        shopSelections
      }
    }
  }
`;

export const VERIFY_TOKEN = gql`
  query VerifyToken($token: String!) {
    verifyToken(token: $token) {
      email
    }
  }
`;
