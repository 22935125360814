import React, { SyntheticEvent } from 'react';
import { Card, Tab, Header } from 'semantic-ui-react';
import styled from '@emotion/styled';
import Login from './Login';
import BusinessApplication from './BusinessApplication';
import { GreenButton } from '../shared/Buttons';

type ThemeProps = {
  theme?: any;
};

const StyledCard = styled(Card)<ThemeProps>`
  &.ui.card {
    margin: 0 auto;
    padding: 60px 8px 20px 8px;
    width: 100%;
    background: #fff;
    font-family: ${({ theme }: ThemeProps) => theme.rui_typography.defaultFont.font};

    @media (min-width: 600px) {
      width: 600px;
      padding: 60px 20px 20px 20px;
      filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    }

    .content {
      padding-top: 8px;
    }

    .header,
    .ui.menu {
      font-family: ${({ theme }: ThemeProps) => theme.rui_typography.defaultFont.font};
    }
  }
`;

const StyledHeader = styled(Header)`
  &.ui.header {
    font-size: 1.45em;
    line-height: 1.6em;
    text-align: center;

    display: flex !important;
    flex-direction: column;
  }
`;

const StyledTab = styled(Tab)`
  .ui.secondary.pointing.menu {
    border-bottom: 2px solid white;
    margin-bottom: 2.5em;

    .item {
      font-size: 16px;
      line-height: 26px;
      font-weight: 700;
      color: ${({ theme }: ThemeProps) => theme.colors.grey};
      padding: 0.75em 1em;

      &.active {
        color: ${({ theme }: ThemeProps) => theme.colors.green};
        border-bottom: 2px solid ${({ theme }: ThemeProps) => theme.colors.green};
      }
    }
  }
`;

type AuthCardProps = {
  shopSelection?: string;
  authTabIndex: string;
  setAuthTabIndex: React.Dispatch<React.SetStateAction<string>>;
};

const AuthCard = ({ shopSelection, authTabIndex, setAuthTabIndex }: AuthCardProps) => {
  let submitButtonRef: any;

  const applicationMap: { [key: string]: JSX.Element } = {
    default: (
      <BusinessApplication
        setSubmitButtonRef={(el: any) => {
          submitButtonRef = el;
        }}
      />
    ),
    Business: (
      <BusinessApplication
        setSubmitButtonRef={(el: any) => {
          submitButtonRef = el;
        }}
      />
    ),
  };

  const shopMap: { [key: string]: string } = {
    default: 'Geschäftskundschaft',
    Business: 'Geschäftskundschaft',
    Feldbotschaft: 'Feldbotschaft & Abonnent:innen',
  };

  const shopRegistrationMap: { [key: string]: string } = {
    default: 'Geschäftskonto',
    Business: 'Geschäftskonto',
    Feldbotschaft: 'Feldbotschaft & Abonnent:innen',
  };

  const headerMap: { [key: string]: JSX.Element } = {
    0: (
      <StyledHeader>
        <span>Anmeldung für</span>
        <span>{shopMap[shopSelection || 'default']}</span>
      </StyledHeader>
    ),
    1: (
      <StyledHeader>
        <span>{shopRegistrationMap[shopSelection || 'default']}</span>
        <span>registrieren</span>
      </StyledHeader>
    ),
  };

  return (
    <StyledCard>
      <Card.Content>
        {headerMap[authTabIndex || '0']}
        <StyledTab
          id="tabHeaders"
          menu={{ secondary: true, pointing: true }}
          activeIndex={authTabIndex}
          onTabChange={(e: SyntheticEvent, { activeIndex }: any) =>
            setAuthTabIndex(String(activeIndex))
          }
          panes={[
            {
              menuItem: 'Anmelden',
              render: () => (
                <Login
                  shopSelection={shopSelection}
                  setSubmitButtonRef={(el: any) => {
                    submitButtonRef = el;
                  }}
                />
              ),
            },
            {
              menuItem: 'Registrieren',
              render: () => applicationMap[shopSelection || 'default'],
            },
          ]}
        />
      </Card.Content>
      <Card.Content extra>
        <GreenButton fluid type="submit" onClick={() => submitButtonRef.click()}>
          {authTabIndex === '0' ? 'Anmelden' : 'Registrieren'}
        </GreenButton>
      </Card.Content>
    </StyledCard>
  );
};

export default AuthCard;
