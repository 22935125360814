import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackPage } from '../helpers/tracking';

const withAnalytics = (WrappedComponent) => {
  const HOC = (props) => {
    const location = useLocation();
    useEffect(() => trackPage(location.pathname), [location.pathname]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};

export default withAnalytics;
