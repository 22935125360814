import React, { memo } from 'react';
import { Checkbox as SemanticCheckbox } from 'semantic-ui-react';
import { useField, ErrorMessage } from 'formik';
import styled from '@emotion/styled';

const RedText = styled.span`
  color: red;
`;

type Props = {
  name: string;
  label?: any;
  [x: string]: any;
};

const Checkbox = memo((props: Props) => {
  const [field, , helpers] = useField({ ...props, type: 'checkbox' });

  return (
    <span className="field">
      {/* eslint-disable-next-line */}
      <label htmlFor={props.id || props.name}>
        <SemanticCheckbox
          {...field}
          onChange={(e, { checked }) => helpers.setValue(!!checked)}
          {...props}
        />
      </label>
      <ErrorMessage component={RedText} name={props.name} />
    </span>
  );
});

export default Checkbox;
