import styled from '@emotion/styled';

export const VeggieStyling = styled.div`
&&& {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 10;
  overflow: hidden;
  display: none;

  @media (min-width: 980px){
    left: 30%;
  }

  @media (min-width: 320px){
    display: block;
  }

  &.portal-bg {
    img {
      position: absolute;
    }

    .lauch {
      top: -75px;
      left: -91px;
      width: 34%;
      min-width: 451px;
      max-width: 490px;

      @media screen and (max-width: 1140px) {
        min-width: 420px;
        top: -60px;
        left: -92px;
      }

      @media screen and (max-width: 980px) {
        min-width: 340px;
        top: 180px;
        left: -72px;
      }

      @media screen and (max-width: 780px) {
        min-width: inherit;
        top: 200px;
        left: -58px;
      }

      @media screen and (max-width: 580px) {
        top: 210px;
        left: -30px;
        width: 220px;
      }

      @media screen and (max-width: 400px) {
        display: none;
      }
    }

    .kartoffel {
      top: -40px;
      right: 14%;
      width: 11%;
      min-width: 145px;
      max-width: 160px;

      @media screen and (max-width: 780px) {
        min-width: 120px;
        top: -40px;
        right: 8%;
      }

      @media screen and (max-width: 580px) {
        right: 8px;
        min-width: 100px;
      }
    }

    .tomate {
      top: 260px;
      right: -60px;
      width: 14%;
      min-width: 176px;
      max-width: 240px;


      @media screen and (max-width: 780px) {
        min-width: 130px;
        top: 220px;
        right: -50px;
        transform: rotate(20deg);
      }

      @media screen and (max-width: 580px) {
        top: 250px;
        min-width: 114px;
        right: 8px;
        transform: rotate(60deg);
      }
    }

    .karotte {
      bottom: -64px;
      right: -10%;
      width: 30%;
      min-width: 350px;
      max-width: 460px;

      @media screen and (max-width: 980px) {
        bottom: -150px;
        right: -18%;
        width: 30%;
        min-width: 350px;
        max-width: 460px;
      }

      @media screen and (max-width: 780px) {
        display: none;
      }
    }

    .paprika {
      bottom: -36px;
      left: 36%;
      width: 16%;
      min-width: 205px;
      max-width: 220px;

      @media screen and (max-width: 780px) {
        left: 54%;
        min-width: 190px;
        bottom: -68px;
      }

      @media screen and (max-width: 580px) {
        display: none;
      }
    }

    .aubergine {
      bottom: 34px;
      left: -64px;
      width: 22%;
      min-width: 261px;
      max-width: 300px;

      @media screen and (max-width: 880px) {
        left: -86px;
        bottom: -26px;
        min-width: 29%;
      }

      @media screen and (max-width: 580px) {
        display: none;
      }
    }
  }
`;

export default VeggieStyling;
