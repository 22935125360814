import React, { memo } from 'react';
import { Formik } from 'formik';
import { Form as SemanticForm } from 'semantic-ui-react';

type FormProps = {
  children?: any;
  [x: string]: any;
};

const Form: React.FC<FormProps> = memo(({ children, ...props }) => {
  const renderChildren = (formikProps: any) => {
    if (children[0]) {
      return children.map((child: any) => {
        if (typeof child === 'function') return child(formikProps);
        return child;
      });
    }
    if (typeof children === 'function') {
      return children(formikProps);
    }
    return children;
  };

  return (
    <Formik onSubmit={() => {}} initialValues={{}} {...props}>
      {(formikProps) => (
        <SemanticForm
          onReset={formikProps.handleReset}
          onSubmit={formikProps.handleSubmit}
          loading={formikProps.isSubmitting}
        >
          {renderChildren(formikProps)}
        </SemanticForm>
      )}
    </Formik>
  );
});

export default Form;
