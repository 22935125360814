import React from 'react';
import { Container } from 'semantic-ui-react';
import styled from '@emotion/styled';
import headerLogo from '../assets/querfeld-logo.svg';
import chevronImage from '../assets/chevron.svg';

const NavigationBarContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 90px;
  background: white;
  box-shadow: rgb(0 0 0 / 50%) 0px -1px 10px;
  z-index: 10;
`;
const FlexContainer = styled(Container)`
  &&&.ui.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @media only screen and (max-width: 767px) {
      width: 100% !important;
    }
  }
`;

const NavigationButton = styled.button`
  background: none;
  border: 0;
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
  z-index: 10;

  @media only screen and (max-width: 767px) {
    width: 30px;
    overflow: hidden;
  }
`;

const LogoContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
`;
const Logo = styled.img`
  height: 42px;
  cursor: pointer;
`;

const Chevron = styled.img`
  margin-right: 13px;
`;

type Props = {
  setAuthTabIndex: React.Dispatch<React.SetStateAction<string>>;
  onBackClick?: () => void;
};

const NavigationBar = ({ setAuthTabIndex, onBackClick }: Props) => (
  <NavigationBarContainer>
    <FlexContainer>
      <NavigationButton onClick={() => onBackClick && onBackClick()}>
        <Chevron src={chevronImage} alt="Zurück" />
        Zurück
      </NavigationButton>

      <LogoContainer>
        <Logo src={headerLogo} alt="Querfeld Logo" onClick={() => setAuthTabIndex('0')} />
      </LogoContainer>
    </FlexContainer>
  </NavigationBarContainer>
);

export default NavigationBar;
