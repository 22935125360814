/* eslint jsx-a11y/label-has-associated-control: 0 */
/* eslint jsx-a11y/label-has-for: 0 */
import React, { useState } from 'react';
import moment from 'moment';
import * as Yup from 'yup';
import { Grid, Header, Message } from 'semantic-ui-react';
import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { Form, Input, Dropdown, Checkbox } from '../wrappers/formik-semantic';
import { digits } from '../helpers/constants';
import { APPLY } from '../graphql/mutations';
import { trackSignup } from '../helpers/tracking';
import { StyledFormikCheckbox } from '../shared/Checkboxes';
import { GreenAnchor } from '../shared/Links';
import config from '../config';

type Props = {
  setSubmitButtonRef: (el: HTMLButtonElement | null) => void;
};

type ThemeProps = {
  theme?: any;
};

const StyledForm = styled.div`
  &.formwrapper {
    .field {
      position: relative;
      display: block;
      margin: 0 0 0.75em;

      a {
        color: ${({ theme }: ThemeProps) => theme.colors.green};
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      /* error styling */
      label + span {
        position: absolute;
        top: 0px;
        right: 0px;
        font-size: 0.92857143em;
      }

      label + span {
        position: absolute;
        top: 0px;
        right: 0px;
        font-size: 0.92857143em;
      }

      label[for='tgcAccepted'],
      label[for='dataPrivacyAccepted'] {
        .ui.checkbox {
          min-height: 24px;
        }

        & + span {
          position: inherit;
          top: inherit;
          right: inherit;
        }
      }
    }
    .delivery-address {
      display: flex;
      flex-direction: row;
      .field:nth-of-type(1) {
        width: 70%;
        @media screen and (min-width: 1024px) {
          width: 80%;
        }
        margin-right: 1rem;
      }

      .field:nth-of-type(2) {
        width: 30%;
      }
    }

    .delivery-window {
      color: black;
      font-size: 0.875em;
      font-weight: 700;
      padding-bottom: 16px;

      .field {
        display: inline;
        margin: 0 0;

        .dropdown {
          font-weight: 400;

          > .dropdown.icon,
          > .search.icon {
            padding: 1.15rem 0.6rem;
          }
        }
      }

      div.textwrapper {
        padding: 0 8px;

        &:first-of-type {
          display: inline-block;
          padding: 0 0 8px 0;
          width: 100%;
        }

        &:last-child {
          padding: 0 0 0 8px;
        }

        span {
          display: inline-block;

          &:first-of-type {
            width: 100%;
            padding: 0 0;
            display: inline-block;
            margin: 0 0 4px 0;
          }

          > label {
            padding: 0 8px;

            .dropdown.selection {
              font-size: 14px;
              line-height: calc(40px-1.05rem);
            }
          }

          > label[for='deliveryDay'] {
            padding: 0 8px 0 0;

            .dropdown.selection {
              min-width: 160px;
            }
          }
        }

        label > br {
          display: none;
        }

        .hint {
          color: ${({ theme }: ThemeProps) => theme.colors.grey};
        }
      }

      .ui.selection {
        min-width: 60px;
        max-width: 110px;
      }
    }
  }
`;

const StyledInput = styled(Input)<ThemeProps>`
  &.ui.input {
    margin-top: 4px;
    margin-bottom: 4px;

    &.disabled {
      opacity: 1;
    }

    input {
      border-radius: ${({ theme }: ThemeProps) => theme.borderRadius};
      border: 1px solid ${({ theme }: ThemeProps) => theme.colors.grey};
      box-shadow: inset 0 0 0 0px ${({ theme }: ThemeProps) => theme.colors.green};
      padding: 1rem 0.875rem;
      color: ${({ theme }: ThemeProps) => theme.colors.black};
      height: 48px;
      transition: all 0.3s ease;

      &:focus {
        border-color: ${({ theme }: ThemeProps) => theme.colors.green};
        box-shadow: inset 0 0 0 2px ${({ theme }: ThemeProps) => theme.colors.green};
        transition: all 0.3s ease;
      }

      &::placeholder {
        color: ${({ theme }: ThemeProps) => theme.colors.grey};
      }

      &:disabled {
        opacity: 1;
        border-color: ${({ theme }: ThemeProps) => theme.colors.grey};
        background-color: ${({ theme }: ThemeProps) => theme.colors.greyLight};
        color: ${({ theme }: ThemeProps) => theme.colors.grey};
      }
    }
  }
`;

const StyledCheckbox = styled(Checkbox)<ThemeProps>`
  &.ui.checkbox {
    input {
      width: 24px;
      height: 24px;
      top: 2px;

      &:before {
        border-color: ${({ theme }: ThemeProps) => theme.colors.grey};
        border-radius: ${({ theme }: ThemeProps) => theme.borderRadius};
      }
      &:checked ~ label {
        &:after {
          color: #fff;
          width: 24px;
          height: 22px;
          top: 6px;
          left: 0px;
        }

        &:before {
          background-color: ${({ theme }: ThemeProps) => theme.colors.green};
          border-color: ${({ theme }: ThemeProps) => theme.colors.green};
          border-radius: ${({ theme }: ThemeProps) => theme.borderRadius};
        }
      }

      &:focus ~ label {
        color: ${({ theme }: ThemeProps) => theme.colors.black};
      }
    }

    label {
      padding-left: 40px;
      text-transform: none;
    }

    label:before {
      width: 24px;
      height: 24px;
      border: 1px solid ${({ theme }: ThemeProps) => theme.colors.black};
      border-radius: ${({ theme }: ThemeProps) => theme.borderRadius};
      margin-top: 2px;
    }
  }
`;

const StyledDropdown = styled(Dropdown)<ThemeProps>`
  &.ui.selection.dropdown {
    border-radius: ${({ theme }: ThemeProps) => theme.borderRadius};
    border: 1px solid ${({ theme }: ThemeProps) => theme.colors.grey};
    padding: 1.05rem 0.875rem;
    color: ${({ theme }: ThemeProps) => theme.colors.black};
    height: 48px;
    user-select: none;
    margin-top: 4px;
    margin-bottom: 4px;

    &.disabled {
      opacity: 1;
      border-color: ${({ theme }: ThemeProps) => theme.colors.grey};
      background-color: ${({ theme }: ThemeProps) => theme.colors.greyLight};
      box-shadow: inset 0 0 0 0px ${({ theme }: ThemeProps) => theme.colors.grey};

      .text {
        color: ${({ theme }: ThemeProps) => theme.colors.grey};
      }

      .dropdown.icon {
        display: none;
      }
    }

    &.active {
      border: 1px solid ${({ theme }: ThemeProps) => theme.colors.green};
      box-shadow: inset 0 0 0 1px ${({ theme }: ThemeProps) => theme.colors.green};
      z-index: 900;

      .menu {
        border: 2px solid ${({ theme }: ThemeProps) => theme.colors.green};
        background-color: ${({ theme }: ThemeProps) => theme.colors.greenLight};

        > .item {
          border-top: 1px solid ${({ theme }: ThemeProps) => theme.colors.greenLight};

          &:hover {
            background-color: #e5f1e3;
          }
          &:first-of-type {
            border-top: 0px;
          }
        }

        .selected {
          background-color: #dbecd9;
        }
      }
    }

    .default.text {
      color: ${({ theme }: ThemeProps) => theme.colors.grey};
    }

    > .dropdown.icon,
    > .search.icon {
      padding: 1.15rem;
    }
  }
`;

const BusinessApplication = ({ setSubmitButtonRef }: Props) => {
  const [apply] = useMutation(APPLY);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const applicationSchema = Yup.object().shape({
    salutation: Yup.string().required('Erforderlich'),
    firstName: Yup.string().required('Erforderlich'),
    lastName: Yup.string().required('Erforderlich'),
    email: Yup.string()
      .required('Erforderlich')
      .matches(
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Ungültige E-Mail'
      ),
    phone: Yup.string().required('Erforderlich'),
    companyName: Yup.string().required('Erforderlich'),
    companySize: Yup.number()
      .typeError('Größe muss eine Zahl sein')
      .positive('Größe muss positiv sein')
      .integer()
      .required('Erforderlich'),
    companyWebsite: Yup.string(),
    deliveryRegion: Yup.string().required('Erforderlich'),
    deliveryStreet: Yup.string().required('Erforderlich'),
    deliveryStreet2: Yup.string().required('Erforderlich'),
    deliveryZipCode: Yup.number()
      .typeError('Postleitzahl muss eine Zahl sein')
      .required('Erforderlich'),
    deliveryCity: Yup.string().required('Erforderlich'),
    deliveryAdditionalLine: Yup.string(),
    deliveryDay: Yup.string().required('Erforderlich'),
    deliveryWindowStart: Yup.number().required('Erforderlich'),
    deliveryWindowEnd: Yup.number().required('Erforderlich'),
    tgcAccepted: Yup.boolean().oneOf(
      [true],
      'Bitte nehmen Sie sich die Zeit die Allgemeinen Geschäftsbedingungen zu lesen, denn zur Nutzung des Querfeld Shops muss der Vereinbarung zugestimmt werden.'
    ),
    dataPrivacyAccepted: Yup.boolean().oneOf(
      [true],
      'Bitte versichern Sie uns, dass Sie die Datenschutzerklärung zur Kenntnis genommen haben.'
    ),
  });

  const initialValues = {
    salutation: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    companyName: '',
    companySize: '',
    companyWebsite: '',
    deliveryRegion: '',
    deliveryStreet: '',
    deliveryStreet2: '',
    deliveryZipCode: '',
    deliveryCity: '',
    deliveryAdditionalLine: '',
    deliveryDay: 'Dienstag',
    deliveryWindowStart: 7,
    deliveryWindowEnd: 10,
    tgcAccepted: '',
    dataPrivacyAccepted: '',
    marketingEmailsWanted: true,
  };

  const handleSubmit = async (values: any, formikApi: any) => {
    try {
      await apply({
        variables: {
          ...values,
          shopSelection: 'Business',
          companySize: parseInt(values.companySize, 10),
          tgcAccepted: values.tgcAccepted ? moment().format() : '',
          dataPrivacyAccepted: values.dataPrivacyAccepted ? moment().format() : '',
          deliveryStreet: `${values.deliveryStreet} ${values.deliveryStreet2}`,
        },
      });
      formikApi.resetForm();
      trackSignup('business');
      setSuccess(true);
      window.scrollTo(0, 0);
      localStorage.setItem('shopPreference', 'Business');
    } catch (error) {
      setSuccess(false);
      setErrorMessage(error.message);
    }
    formikApi.setSubmitting(false);
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={applicationSchema}
    >
      {({ values, setFieldValue }: any) => {
        return (
          <>
            {errorMessage && <Message negative header="Hoppla!" content={errorMessage} />}
            {success && (
              <Message positive>
                <Message.Header>Vielen Dank für Ihre Anmeldung!</Message.Header>
                <h5>
                  Bitte prüfen Sie Ihr angegebenes Postfach. Sollten Sie keine Nachricht bekommen
                  haben, kontaktieren Sie bitte den Kundenservice unter&nbsp;
                  <a
                    href="mailto: kundenservice@querfeld.bio"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    kundenservice@querfeld.bio
                  </a>
                  .
                </h5>
                <h5>
                  Ihre Eingaben werden von uns geprüft und Sie werden daraufhin für die Nutzung des
                  Shops freigeschaltet.
                </h5>
              </Message>
            )}
            <StyledForm className="formwrapper">
              <Header as="h3">Angaben zu Ihrem Betrieb</Header>
              <StyledInput label="Firmenname" name="companyName" />
              <StyledInput
                label="Küchengröße (Ø Bedarf in KG/Woche)"
                type="number"
                name="companySize"
              />
              <StyledInput label="Webseite" name="companyWebsite" />
              <Header as="h3">Nutzerinformationen</Header>
              <StyledDropdown
                selection
                label="Anrede"
                name="salutation"
                placeholder="Bitte auswählen"
                options={[
                  { value: 'MR', text: 'Herr' },
                  { value: 'MRS', text: 'Frau' },
                ]}
              />
              <Grid>
                <Grid.Column width={8}>
                  <StyledInput label="Vorname" name="firstName" className="small-input" />
                </Grid.Column>
                <Grid.Column width={8}>
                  <StyledInput label="Nachname" name="lastName" className="small-input" />
                </Grid.Column>
              </Grid>
              <StyledInput label="Email" name="email" />
              <StyledInput label="Telefonnummer" name="phone" />
              <Header as="h3">Lieferadresse</Header>
              <StyledDropdown
                selection
                label={
                  <>
                    Lieferregion (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="mailto:kundenservice@querfeld.bio"
                    >
                      Fragen zu unseren Lieferregionen?
                    </a>
                    )
                  </>
                }
                name="deliveryRegion"
                placeholder="Bitte auswählen"
                options={[
                  { value: 'BER', text: 'Berlin' },
                  { value: 'MUC', text: 'München' },
                  { value: 'NRW', text: 'NRW' },
                ]}
              />
              <div className="delivery-address">
                <StyledInput label="Straße" name="deliveryStreet" />
                <StyledInput label="Hausnummer" name="deliveryStreet2" />
              </div>
              <StyledInput label="Postleitzahl" name="deliveryZipCode" />
              <StyledInput label="Stadt" name="deliveryCity" />
              <StyledInput label="Adresszusatz" name="deliveryAdditionalLine" />
              <div className="delivery-window">
                <div className="textwrapper">
                  <span>Präferierte Lieferung am:</span>
                  <StyledDropdown
                    selection
                    name="deliveryDay"
                    options={[
                      { text: 'Dienstag', value: 'Dienstag' },
                      { text: 'Freitag', value: 'Freitag' },
                    ]}
                  />
                  <span>von</span>
                  <StyledDropdown
                    selection
                    name="deliveryWindowStart"
                    className="deliveryWindow"
                    options={digits.slice(5, 11)}
                    onChangeCallback={(v: number) => {
                      if (values.deliveryWindowEnd < v + 3) {
                        setFieldValue('deliveryWindowEnd', v + 3);
                      }
                    }}
                  />
                  <span>bis</span>
                  <StyledDropdown
                    selection
                    name="deliveryWindowEnd"
                    options={digits.slice(values.deliveryWindowStart + 3, 14)}
                  />
                  <span>Uhr</span>
                  <p className="hint information">
                    Wir werden Ihre gewünschte Lieferzeit bei der Planung berücksichtigen, können
                    die Lieferzeit aber nicht garantieren.
                  </p>
                </div>
                <br />
              </div>
              <StyledCheckbox
                name="tgcAccepted"
                label={
                  <label htmlFor="field_checkbox_tgcAccepted">
                    Ich habe die{' '}
                    <GreenAnchor
                      href={`${config.website.baseURL}/business-agb`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Allgemeinen Geschäftsbedingungen
                    </GreenAnchor>{' '}
                    gelesen und stimme ihnen hiermit zu.
                  </label>
                }
              />
              <StyledCheckbox
                name="dataPrivacyAccepted"
                label={
                  <label htmlFor="field_checkbox_dataPrivacyAccepted">
                    Ich habe die{' '}
                    <GreenAnchor
                      href={`${config.website.baseURL}/datenschutz`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Datenschutzerklärung
                    </GreenAnchor>{' '}
                    zur Kenntnis genommen.
                  </label>
                }
              />
              <StyledFormikCheckbox
                name="marketingEmailsWanted"
                label={
                  <label htmlFor="field_checkbox_marketingEmailsWanted">
                    Ich möchte Infos und Neuigkeiten zu Querfeld per Mail erhalten.
                  </label>
                }
              />
            </StyledForm>
            <button hidden type="submit" ref={(el) => setSubmitButtonRef(el)} />
          </>
        );
      }}
    </Form>
  );
};

export default BusinessApplication;
