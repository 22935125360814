import React from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';
import config from '../config';
import { GreenButton } from '../shared/Buttons';
import potatoe from '../assets/404-potatoe.png';

type ThemeProps = {
  theme?: any;
};

const Footer = styled.div<ThemeProps>`
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  padding-top: 24px;

  a {
    color: ${({ theme }) => theme.colors.green};
  }
`;

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  margin: 0 auto;
  max-width: 620px;

  * {
    margin-bottom: 24px !important;
  }

  img {
    width: 40%;
    margin-bottom: 0 !important;
  }
`;

const HandwrittenTitle = styled.span<ThemeProps>`
  font-family: ${({ theme }) => theme.rui_typography.handwrittenFont.font};
  font-style: normal;
  font-weight: 500;
  font-size: 67px;
  line-height: 67px;
  text-align: center;
`;

const NotFound = () => {
  const history = useHistory();

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${window.location.origin}${window.location.pathname}`} />
      </Helmet>
      <NotFoundContainer>
        <img src={potatoe} alt="Potatoe" />
        <HandwrittenTitle>Seite nicht gefunden</HandwrittenTitle>
        <GreenButton onClick={() => history.push('/')}>Zur Startseite</GreenButton>
      </NotFoundContainer>
      <Footer>
        Gibt es noch Fragen? <br />
        <a href={`${config.website.baseURL}/faq`}>Hier gehts zu häufig gestellten Fragen</a>
      </Footer>
    </>
  );
};

export default NotFound;
