import { useQuery } from '@apollo/client';

// TODO: fix this workaround when useLazyQuery is fixed to return promise
// https://github.com/apollographql/react-apollo/issues/3499
export const useImperativeQuery = (query) => {
  const { refetch } = useQuery(query, { skip: true });

  const imperativelyCallQuery = (variables) => {
    return refetch(variables);
  };

  return imperativelyCallQuery;
};

export default useImperativeQuery;
