import { gql } from '@apollo/client';

export const APPLY = gql`
  mutation Apply(
    $shopSelection: String!
    $salutation: String
    $firstName: String!
    $lastName: String!
    $phone: String!
    $email: String!
    $companyName: String!
    $companySize: Int
    $deliveryCompany: String
    $deliveryRegion: String!
    $deliveryStreet: String!
    $deliveryZipCode: String!
    $deliveryCity: String!
    $deliveryAdditionalLine: String
    $deliveryDay: String!
    $deliveryWindowStart: Int!
    $deliveryWindowEnd: Int!
    $pickupWindowStart: String
    $pickupWindowEnd: String
    $pickupDay: String
    $tgcAccepted: String!
    $dataPrivacyAccepted: String!
    $marketingEmailsWanted: Boolean
    $feldbotschaftCustomerType: String
    $depositBoxDropoffAllowed: Boolean
  ) {
    apply(
      shopSelection: $shopSelection
      salutation: $salutation
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      email: $email
      companyName: $companyName
      companySize: $companySize
      deliveryCompany: $deliveryCompany
      deliveryRegion: $deliveryRegion
      deliveryStreet: $deliveryStreet
      deliveryZipCode: $deliveryZipCode
      deliveryCity: $deliveryCity
      deliveryAdditionalLine: $deliveryAdditionalLine
      deliveryDay: $deliveryDay
      deliveryWindowStart: $deliveryWindowStart
      deliveryWindowEnd: $deliveryWindowEnd
      pickupDay: $pickupDay
      pickupWindowStart: $pickupWindowStart
      pickupWindowEnd: $pickupWindowEnd
      tgcAccepted: $tgcAccepted
      dataPrivacyAccepted: $dataPrivacyAccepted
      marketingEmailsWanted: $marketingEmailsWanted
      feldbotschaftCustomerType: $feldbotschaftCustomerType
      depositBoxDropoffAllowed: $depositBoxDropoffAllowed
    ) {
      id
    }
  }
`;

export const REGISTER_MEMBER = gql`
  mutation IndirectCustomerRegister(
    $salutation: String
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $email: String!
    $password: String!
    $marketingEmailsWanted: Boolean!
    $tgcAccepted: String!
    $dataPrivacyAccepted: String!
    $originApp: String
  ) {
    indirectCustomerRegister(
      salutation: $salutation
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      email: $email
      password: $password
      marketingEmailsWanted: $marketingEmailsWanted
      tgcAccepted: $tgcAccepted
      dataPrivacyAccepted: $dataPrivacyAccepted
      originApp: $originApp
    ) {
      id
    }
  }
`;

export default APPLY;
