import styled from '@emotion/styled';
import Autocomplete from 'react-google-autocomplete';
import { Input } from '../wrappers/formik-semantic';

type ThemeProps = {
  theme?: any;
};

export const StyledFormikInput = styled(Input)<ThemeProps>`
  &.ui.input {
    margin-top: 4px;
    margin-bottom: 4px;

    &.disabled {
      opacity: 1;
    }

    input {
      border-radius: ${({ theme }: ThemeProps) => theme.borderRadius};
      border: 1px solid ${({ theme }: ThemeProps) => theme.colors.grey};
      box-shadow: inset 0 0 0 0px ${({ theme }: ThemeProps) => theme.colors.green};
      padding: 1rem 0.875rem;
      color: ${({ theme }: ThemeProps) => theme.colors.black};
      height: 48px;
      transition: all 0.3s ease;

      &:focus {
        border-color: ${({ theme }: ThemeProps) => theme.colors.green};
        box-shadow: inset 0 0 0 2px ${({ theme }: ThemeProps) => theme.colors.green};
        transition: all 0.3s ease;
      }

      &::placeholder {
        color: ${({ theme }: ThemeProps) => theme.colors.grey};
      }

      &:disabled {
        opacity: 1;
        border-color: ${({ theme }: ThemeProps) => theme.colors.grey};
        background-color: ${({ theme }: ThemeProps) => theme.colors.greyLight};
        color: ${({ theme }: ThemeProps) => theme.colors.grey};
      }
    }
  }
`;

export const StyledAddressAutocomplete = styled(Autocomplete)<ThemeProps>`
  &&&& {
    margin-top: 4px;
    margin-bottom: 4px;

    &.disabled {
      opacity: 1;
    }

    border-radius: ${({ theme }: ThemeProps) => theme.borderRadius};
    border: 1px solid ${({ theme }: ThemeProps) => theme.colors.grey};
    box-shadow: inset 0 0 0 0px ${({ theme }: ThemeProps) => theme.colors.green};
    padding: 1rem 0.875rem;
    color: ${({ theme }: ThemeProps) => theme.colors.black};
    height: 48px;
    transition: all 0.3s ease;

    &:focus {
      border-color: ${({ theme }: ThemeProps) => theme.colors.green};
      box-shadow: inset 0 0 0 2px ${({ theme }: ThemeProps) => theme.colors.green};
      transition: all 0.3s ease;
    }

    &::placeholder {
      color: ${({ theme }: ThemeProps) => theme.colors.grey};
    }

    &:disabled {
      opacity: 1;
      border-color: ${({ theme }: ThemeProps) => theme.colors.grey};
      background-color: ${({ theme }: ThemeProps) => theme.colors.greyLight};
      color: ${({ theme }: ThemeProps) => theme.colors.grey};
    }
  }
`;

export default StyledFormikInput;
